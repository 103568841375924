export default (opened = false, isHeaderClickable = true) => {
  var coll = document.getElementsByClassName("bfg-collapse");
  var icons = document.getElementsByClassName("collapse-icon");
  var button = document.querySelector(".collapsable-button");

  const elements = isHeaderClickable ? coll : icons;

  for (let i = 0; i < elements.length; i++) {
    if (!elements[i].hasAttribute("data-listener-added")) {
      elements[i].addEventListener("click", handleCollapseClick.bind(coll[i]));
      elements[i].setAttribute("data-listener-added", "true");
    }

    if (coll[i].getAttribute("data-opened") === "true") {
      toggleContent(coll[i]);
    }
  }

  if (button) {
    button.addEventListener("click", function () {
      toggleContent(coll[0]);
    });
  }

  if (opened) {
    for (var i = 0; i < coll.length; i++) {
      toggleContent(coll[i]);
    }
  }
};

function handleCollapseClick() {
  toggleContent(this);
}

function toggleContent(element) {
  element.classList.toggle("active");
  var content = element.nextElementSibling;
  if (content) {
    content.classList.toggle("opened");
    content.style.display = content.classList.contains("opened") ? "block" : "";
    content.style.maxHeight = content.classList.contains("opened")
      ? content.scrollHeight * 1.5 + "px"
      : "";
  }
}
